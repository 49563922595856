import { useCallback, useMemo } from "react";
import { WaveSurfer, WaveForm } from "wavesurfer-react";
// @ts-ignore
import MicrophonePlugin from "wavesurfer.js/dist/plugin/wavesurfer.microphone.min";

export default function Waveform() {
  const plugins = useMemo(() => {
    return [
      {
        plugin: MicrophonePlugin,
        options: {},
      },
    ];
  }, []);

  const handleWSMount = useCallback((ws) => {
    ws.microphone.start();
  }, []);
  return (
    <>
      <WaveSurfer plugins={plugins} onMount={handleWSMount}>
        <WaveForm id="waveform" waveColor={"black"}/>
      </WaveSurfer>
    </>
  );
}
