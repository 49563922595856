import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

const GENDERS = ["Male", "Female", "Other"];
const ACCENTS = [
  "Indian-North",
  "Indian-South",
  "Indian-West",
  "Indian-East",
  "Indian-Central",
  "American",
  "Filipino-Cebuano",
  "Filipino-Tagalog",
  "Filipino-Ilocano",
  "Filipino-Hiligaynon",
  "Filipino-Bicolano",
  "Filipino-Waray",
  "Filipino-Pampango",
  "Filipino-Pangasinense",
  "Filipino-Other",
];

export function Questionnaire(props: { setQuestionnaire: any }) {
  const params = useParams();
  const [gender, setGender] = useState("");
  const [age, setAge] = useState(0);
  const [accent, setAccent] = useState("");
  const [name, setName] = useState("");
  const [consent, setConsent] = useState(false);

  const handleSubmit = (e: any) => {
    if (!consent) {
      return;
    }
    props.setQuestionnaire({
      gender,
      age,
      accent,
      name,
    });
    e.preventDefault();
  };

  return (
    <Box id="questionnaireForm" component="form" onSubmit={handleSubmit}>
      <h3>Welcome to Meaning recording application</h3>
      <div className="questionnaireBox">
        <InputLabel id="name">Full name or ID</InputLabel>
        <TextField
          variant="outlined"
          required={true}
          onChange={(e) => setName(e.target.value)}
        />
        <InputLabel id="age">Age</InputLabel>
        <TextField
          type="number"
          variant="outlined"
          required={true}
          onChange={(e) => setAge(Number(e.target.value))}
        />
        <InputLabel id="genderSelect">Gender</InputLabel>
        <Select
          id="genderSelect"
          value={gender}
          onChange={(event) => setGender(event?.target.value)}
          label="Gender"
          required={true}
        >
          {GENDERS.map((gender) => {
            return (
              <MenuItem key={gender} value={gender}>
                {gender}
              </MenuItem>
            );
          })}
        </Select>
        <InputLabel id="accentSelect">Accent</InputLabel>
        <Select
          id="accentSelect"
          value={accent}
          onChange={(event) => setAccent(event?.target.value)}
          label="Accent"
          required={true}
        >
          {ACCENTS.map((accent) => {
            return (
              <MenuItem key={accent} value={accent}>
                {accent}
              </MenuItem>
            );
          })}
        </Select>
        <FormControl required error={!consent}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => setConsent(event.target.checked)}
              />
            }
            label={
              <span>
                Accept&nbsp;
                <a
                  href={params.bucket + "/terms"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and Conditons
                </a>
              </span>
            }
          />
          <FormHelperText>
            {!consent && "You need to accept the terms"}
          </FormHelperText>
        </FormControl>
        <Button disabled={!consent} variant="contained" type="submit">
          Let's start
        </Button>
      </div>
    </Box>
  );
}
