import { useState, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import { getIdentificator, getSessionId } from "../db";
import { Event } from "../interfaces";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import ManualUploader from "../components/manual_uploader";
import ShortcutPanel from "../components/shortcut_panel";
import { Questionnaire } from "../components/questionnaire";
import Waveform from "../components/waveform";
import Countdown from "react-countdown";
import { useHotkeys } from "react-hotkeys-hook";
import TimerIcon from "@mui/icons-material/Timer";
import { Card } from "@mui/material";

interface Config {
  utterances: String[];
}

interface QuestionnaireData {
  age: number;
  name: string;
  gender: string;
  accent: string;
}

export default function RecordPage() {
  const params = useParams();
  const identificator = getIdentificator();

  const [sessionId] = useState(getSessionId());
  const [recorder, setRecorder] = useState<MediaRecorder | undefined>(
    undefined
  );
  const [config, setConfig] = useState<Config>({ utterances: [] });
  const [configFailed, setConfigFailed] = useState(false);

  const [startTimestamp, setStartTimestamp] = useState<number>(Date.now());
  const [started, setStarted] = useState(false);
  const countdownRef = useRef(null);

  const [blobs, setBlobs] = useState<Blob[]>([]);
  const [events, setEvents] = useState<Event[]>([]);

  const [prematureFinish, setPrematureFinish] = useState<boolean>(false);
  const [errors] = useState<string[]>([]);

  const [questionnaire, setQuestionnaire] = useState<
    QuestionnaireData | undefined
  >(undefined);

  const consent = useMemo(() => {
    return questionnaire !== undefined;
  }, [questionnaire]);

  useEffect(() => {
    fetch(
      `https://${process.env.REACT_APP_DATA_BUCKET}.s3.amazonaws.com/${params.bucket}/config.json`
    )
      .then((res) => res.json())
      .then((res) => {
        setConfig(res);
      })
      .catch(() => {
        setConfigFailed(true);
      });
  }, [params.bucket]);

  useEffect(() => {
    if (consent) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream) => {
          const options = { mimeType: "audio/webm" };

          const mediaRecorder = new MediaRecorder(stream, options);

          mediaRecorder.addEventListener("dataavailable", async function (e) {
            const data = await e.data.arrayBuffer();
            setBlobs(prevBlobs => [...prevBlobs, new Blob([data], { type: "audio/webm" })]);
          });

          mediaRecorder.start(1000 * 2); // in ms,
          setStartTimestamp(Date.now());
          setRecorder(mediaRecorder);
        });
    }
  }, [params.bucket, identificator, consent]);


  const stopRecordingHandler = () => {
    recorder?.stop();
  };

  const steps = config?.utterances.map((u, idx) => {
    return {
      label: idx + 1,
      description: u,
    };
  });

  const [activeStep, setActiveStep] = useState(0);
  const [recordedSteps, setRecordedSteps] = useState<Number[]>([]);
  const [finishedSteps, setFinishedSteps] = useState<Number[]>([]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleRerecord = (step: number) => () => {
    setRecordedSteps(recordedSteps.filter((s) => s !== step));
    setFinishedSteps(finishedSteps.filter((s) => s !== step));
    addEvent("RERECORD", step);
  };

  const addEvent = (type: Event["type"], index: number) => {
    setEvents(prevEvents => [...prevEvents, { timestamp: Date.now() - startTimestamp, type, index }]);
  };

  const handleStartRecording = (step: number) => () => {
    if (step === 0) {
      setStarted(true);
    }
    setRecordedSteps([...recordedSteps, step]);
    addEvent("START", step);
  };

  const handleStopRecording = (step: number) => () => {
    setFinishedSteps([...finishedSteps, step]);
    addEvent("STOP", step);
    recorder?.requestData();
  };

  const handleSkip = (step: number) => () => {
    setFinishedSteps([...finishedSteps, step]);
    handleNext();
    addEvent("SKIP", step);
    recorder?.requestData();
  };

  const handlePrematureExit = () => {
    setActiveStep(steps?.length ?? 0);
    setPrematureFinish(true);
    stopRecordingHandler();
  };

  const isFinished = finishedSteps.includes(activeStep);
  const isRecording = !isFinished && recordedSteps.includes(activeStep);
  const isStarting = !isRecording && !isFinished;

  useHotkeys(
    "s",
    () => {
      if (isRecording) {
        handleStopRecording(activeStep)();
      } else if (isStarting) {
        handleStartRecording(activeStep)();
      }
    },
    [activeStep, isRecording, isStarting]
  );

  useHotkeys(
    "r",
    () => {
      if (isFinished) {
        handleRerecord(activeStep)();
      }
    },
    [activeStep, isFinished]
  );

  useHotkeys(
    "k",
    () => {
      if (isRecording) {
        handleSkip(activeStep)();
      }
    },
    [activeStep, isRecording]
  );

  useHotkeys(
    "d",
    () => {
      // next utterance
      if (isFinished) {
        handleNext();
      }
    },
    [activeStep, isFinished]
  );

  interface ICountdownRenderer {
    formatted: {
      minutes: string;
      seconds: string;
    };
    completed: boolean;
  }
  const countdownRenderer = ({
    formatted: { minutes, seconds },
    completed,
  }: ICountdownRenderer) => {
    if (completed) {
      // Render a completed state
      return (
        <Dialog open={true}>
          <Box>
            You exceeded the time limit to record the dialogue. Please refresh
            the page and record again.
          </Box>
        </Dialog>
      );
    } else {
      // Render a countdown
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  const countdownTime = useMemo(() => {
    if (!consent) {
      return Date.now() + 60 * 360 * 1000;
    }
    return Date.now() + 60 * 30 * 1000;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [started, consent]);

  useEffect(() => {
    if (countdownRef && started) {
      // @ts-ignore
      countdownRef.current?.getApi().start();
    }
  }, [countdownRef, started]);

  return (
    <>
      {configFailed ? (
        <div>
          This script is no longer available. Please use a different script.
        </div>
      ) : (
        <>
          <Dialog open={!consent}>
            <Questionnaire setQuestionnaire={setQuestionnaire} />
          </Dialog>
          {consent && steps.length > 0 ? (
            <>
              <div className="header">
                <img
                  className="logo"
                  src="/meaning_logo.png"
                  alt="meaning logo"
                />
                <div>
                  <TimerIcon />
                  <Countdown
                    autoStart={false}
                    ref={countdownRef}
                    date={countdownTime}
                    renderer={countdownRenderer}
                    zeroPadTime={2}
                  />
                </div>
                <div className="waveformContainer">
                  <Waveform />
                </div>
                <ShortcutPanel />
              </div>
              <div className="columns">
                <div className="instructions">
                  <Card>
                    <Typography variant="h5" component="h2">
                      Recording instructions
                    </Typography>
                    <ol>
                      <li>
                        If the waveform displayed above is not moving, please
                        check your input device.
                      </li>
                      <li>
                        After clicking on the start recording button you will
                        have 30 minutes to read the dialogue and have a time
                        indicator above. Do not worry, the time limit is very
                        generous and you should be able to finish the dialogue
                        in time.
                      </li>
                      <li>
                        If you find a part of the dialogue difficult to read,
                        you can press the “Skip” button to skip it.
                      </li>
                      <li>
                        If you need to leave early, you can press the “Finish
                        recording and upload” button at any time.
                      </li>
                      <li>
                        Make sure to wait for the upload to finish before
                        closing this tab.
                      </li>
                    </ol>
                  </Card>
                  <Button
                    variant="outlined"
                    onClick={() => handlePrematureExit()}
                  >
                    Finish recording and upload
                  </Button>
                </div>
                <div className="steps">
                  <Box
                    sx={{
                      maxWidth: 400,
                      alignSelf: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Stepper activeStep={activeStep} orientation="vertical">
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepLabel>{step.label}</StepLabel>
                          <StepContent>
                            <Typography>
                              {recordedSteps.includes(index)
                                ? step.description
                                : "Prepare to record the next utterance"}
                            </Typography>
                            <Box sx={{ mb: 2 }}>
                              <div>
                                {finishedSteps.includes(index) ? (
                                  <>
                                    <Button
                                      variant="contained"
                                      onClick={handleNext}
                                      sx={{ mt: 1, mr: 1 }}
                                    >
                                      Next utterance
                                    </Button>
                                    <Button
                                      variant="contained"
                                      onClick={handleRerecord(index)}
                                      sx={{ mt: 1, mr: 1 }}
                                    >
                                      Re-record this utterance
                                    </Button>
                                  </>
                                ) : recordedSteps.includes(index) ? (
                                  <>
                                    <Button
                                      variant="contained"
                                      onClick={handleStopRecording(index)}
                                      sx={{ mt: 1, mr: 1 }}
                                    >
                                      Stop recording
                                    </Button>
                                    <Button
                                      variant="contained"
                                      onClick={handleSkip(index)}
                                      sx={{ mt: 1, mr: 1 }}
                                    >
                                      Skip
                                    </Button>
                                  </>
                                ) : (
                                  <Button
                                    variant="contained"
                                    onClick={handleStartRecording(index)}
                                    sx={{ mt: 1, mr: 1 }}
                                  >
                                    Start recording
                                  </Button>
                                )}
                              </div>
                            </Box>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                    {activeStep === steps.length && (
                      <ManualUploader
                        bucket={params.bucket || ""}
                        identificator={identificator}
                        sessionId={sessionId}
                        questionnaire={questionnaire as Object}
                        prematureFinish={prematureFinish}
                          errors={errors}
                          blobs={blobs}
                          events={events}
                      />
                    )}
                  </Box>
                </div>
              </div>
            </>
          ) : (
            <div />
          )}
        </>
      )}
    </>
  );
}
