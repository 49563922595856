
const upload = async (path: string, blob: Blob, events: Object) => {
  const S3_SIGNED_URL_ENDPOINT = `${process.env.REACT_APP_UPLOAD_LAMBDA}/${path}`;
  const presignedURLs = await fetch(S3_SIGNED_URL_ENDPOINT).then((r) =>
    r.json()
  );

  return Promise.all([
    fetch(presignedURLs.recordingUploadURL, {
      method: "PUT",
      body: blob,
      headers: {
        "Content-Type": "audio/webm",
      },
    }),

    fetch(presignedURLs.eventsUploadURL, {
      method: "PUT",
      body: JSON.stringify(events),
      headers: {
        "Content-Type": "application/json",
      },
    }),
  ]);
};

export { upload };
