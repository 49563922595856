import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

export default function ShortcutPanel() {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">Keyboard shortcuts</Typography>
        <Typography variant="body1">
          <ul>
            <li>S: Start/stop recording</li>
            <li>R: Rerecord recording</li>
            <li>K: Skip recording</li>
            <li>D: Next utterance</li>
          </ul>
        </Typography>
      </CardContent>
    </Card>
  );
}
