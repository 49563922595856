import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import { db } from "../db"
import { upload } from "../upload";
import { Event } from "../interfaces";

interface IManualUploader {
    identificator: string
    sessionId: string
    bucket: string
    questionnaire: Object
    prematureFinish: boolean,
    errors: Object[],
    blobs: Blob[],
    events: Event[]
}

enum UploadState {
    UPLOADING,
    UPLOADED,
    FAILED
}

export default function ManualUploader({identificator, sessionId, bucket, questionnaire, prematureFinish, errors, blobs, events}: IManualUploader) {
    const [uploadState, setUploadState] = useState<UploadState>(UploadState.UPLOADING);
    const uploadData = async () => {


        const blob = new Blob(blobs)

        const uploadEvents = {
            timestamps: events,
            questionnaire,
            prematureFinish,
            errors
        }
        
        await upload(`${bucket}/${identificator}`, blob, uploadEvents);
        return db.delete()
    }
    useEffect(() => {
        setUploadState(UploadState.UPLOADING);

        uploadData().then(() => {
            setUploadState(UploadState.UPLOADED);
        }).catch(() => {
            setUploadState(UploadState.FAILED);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [identificator, sessionId, bucket])


    const renderState = (state: UploadState) => {
        switch (state) {
            case UploadState.UPLOADING:
                return <Typography>Uploading the data, please wait...</Typography>
            case UploadState.UPLOADED:
                return <Typography>Uploaded! You can close this page now.</Typography>
            case UploadState.FAILED:
                return <Typography>Failed!</Typography>
        }
    } 
    return (
        <>
            <Box>
                <Typography variant="h6">
                    {renderState(uploadState)}
                </Typography>
            </Box>
        </>
    )
}