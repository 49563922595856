import { v4 as uuid } from "uuid";
import Dexie, { Table } from "dexie";
import {Event} from "./interfaces";

const getIdentificator = (): string => {
  // check if identificator is in localStorage
  const stored = localStorage.getItem("identificator");
  if (stored) {
    return stored;
  }
  // generate identificator
  const generated = uuid();
  localStorage.setItem("identificator", generated);
  return generated;
};

const getSessionId = (): string => uuid()

export interface IRecording {
  id?: number;
  index: number;
  sessionId: string;
  identificator: string;
  bucket: string;
  audio: Blob;
}

export interface IEvent {
  id?: number;
  index: number;
  sessionId: string;
  identificator: string;
  bucket: string;
  event: Event
}

export class RecordingDB extends Dexie {
  recordings!: Table<IRecording>;
  events!: Table<IEvent>;

  constructor() {
    super('recordingDb')
    this.version(1).stores({
      // These decide what fields are indexed, do not index the (large) blob
      // https://dexie.org/docs/Version/Version.stores()#warning
      // Boolean also can't be indexed
      // https://dexie.org/docs/Version/Version.stores()#indexable-types
      recordings: "++id, index, sessionId, bucket, identificator",
      events: "++id, index, sessionId, bucket, identificator"
    }); 
  } 
}

const db = new RecordingDB();

export {
  getIdentificator,
  getSessionId,
  db
};
