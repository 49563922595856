import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import RecordPage from "./pages/Record.page";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TermsPage from "./pages/Terms.page";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#730FC3",
      },
    },
  });

  return (
    <div className="App">
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path=":bucket" element={<RecordPage />} />
            <Route path=":bucket/terms" element={<TermsPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
