import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface IConfig {
  terms: string;
}

export default function TermsPage() {
  const params = useParams();
  const [config, setConfig] = useState({} as IConfig);
  const [loaded, setLoaded] = useState(false);
  const [terms, setTerms] = useState("");

  useEffect(() => {
    fetch(
      `https://${process.env.REACT_APP_DATA_BUCKET}.s3.amazonaws.com/${params.bucket}/config.json`
    )
      .then((res) => res.json())
      .then((res) => {
        setConfig(res);
      })
      .finally(() => {
        setLoaded(true);
      });
  }, [params.bucket]);

  useEffect(() => {
    if (loaded && config.terms) {
      setTerms(config.terms);
    } else if (loaded) {
      const defaultTerms = `By accepting our terms and conditions you hereby agree that Meaning.Team
      shall own all right, title and interest relating to the recordings
      captured using the Meaning recording application and any information,
      works of authorship, or other work product made or conceived or reduced to
      practice, in whole or in part, by or for or on behalf of you in connection
      with the Services or Confidential Information. You hereby grant
      Mearning.Team Inc and its successors a perpetual, irrevocable, worldwide,
      royalty-free, non-exclusive, transferable, sublicensable right and license
      to exploit and exercise all such technology and intellectual property
      rights in support of Meaning.Team Inc exercise or exploitation of the
      Services or Inventions.`;
      setTerms(defaultTerms);
    }
  }, [loaded, config.terms]);

  return (
    <div className="terms">
      <Typography variant="h6">
        Meaning Recording Application Terms and Conditions
      </Typography>
      {terms}
    </div>
  );
}
